<template>
  <div class="row px-3 mt-3">
    <div class="col-xl-2 col-lg-3 col-md-4 col-12">
      <div class="d-flex flex-row align-items-center mb-2">
        <i class="ri-file-edit-line ri-2x align-middle mr-1" />
        <div class="v-divider" />
        <h1 class="mb-0 mx-1">Composeur</h1>
      </div>

      <b-input-group class="mb-2">
        <b-input-group-prepend>
          <span class="input-group-text"><i class="ri-search-line" /></span>
        </b-input-group-prepend>
        <b-form-input
          v-model="searchInput"
          class="search-input py-2"
          placeholder="Rechercher un modèle"
          debounce="500"
          type="search"
        />
      </b-input-group>
      <router-link
        :to="{ name: 'newModel' }"
        block
        class="btn btn-primary mb-3"
        style="display: block"
      >
        <i class="ri-add-line align-middle ri-xl mr-50" />Créer un modèle
      </router-link>
    </div>

    <div class="col-xl-10 col-lg-9 col-md-8 col-12">
      <div v-if="!hideRecentModels" class="mb-2">
        <h3 class="mb-2">Modèles récents</h3>
        <div class="row d-flex flex-row align-items-center card-group mb-1">
          <router-link
            v-for="model in recents"
            :key="`recent-models-${model.id}`"
            :to="{ name: 'editor', params: { id: model.id } }"
            class="col-xl-3 col-md-6 mb-2"
          >
            <card-file
              :title="model.title"
              :type="model.type"
              :created-at="model.createdAt"
              :updated-at="model.updatedAt"
            />
          </router-link>
        </div>
      </div>

      <h3 class="mb-2">Tous les modèles</h3>

      <vue-good-table
        mode="remote"
        style-class="vgt-table"
        :total-rows="totalRecords"
        :is-loading.sync="isTableLoading"
        :pagination-options="{ enabled: true }"
        :sort-options="{ enabled: false, initialSortBy: serverParams.sort }"
        :rows="rows"
        :columns="columns"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
      >
        <div slot="loadingContent" class="text-center">Chargement...</div>
        <div slot="emptystate" class="text-center">Aucun modèle à afficher</div>
        <template slot="table-row" slot-scope="props">
          <!-- Column: createdAt -->
          <span v-if="props.column.field === 'createdAt'" class="text-nowrap">
            {{ dateTimeToStr(props.row.createdAt) }}
          </span>

          <!-- Column: updatedAt -->
          <span
            v-else-if="props.column.field === 'updatedAt'"
            class="text-nowrap"
          >
            {{ dateTimeToStr(props.row.updatedAt) }}
          </span>

          <!-- Column: updatedAt -->
          <span v-else-if="props.column.field === 'type'" class="text-nowrap">
            <tag-file-type :type="props.row.type" />
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>

              <b-dropdown-item
                :to="{ name: 'editor', params: { id: props.row.id } }"
              >
                <i class="ri-pencil-line mr-50 ri-lg align-middle" />
                <span>Éditer</span>
              </b-dropdown-item>
              <b-dropdown-item @click="openGenericLinkModal(props.row)">
                <i class="ri-links-line mr-50 ri-lg align-middle" />
                <span>Lien générique</span>
              </b-dropdown-item>
              <b-dropdown-item @click="deleteModel(props.row)">
                <i class="ri-delete-bin-5-line mr-50 ri-lg align-middle" />
                <span>Supprimer</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">Afficher </span>
              <b-form-select
                v-model="serverParams.perPage"
                :options="['5', '10', '20', '50', '100']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap">
                Modèle(s) par page (Total : {{ props.total }})
              </span>
            </div>
            <div>
              <b-pagination
                :value="serverParams.page"
                :total-rows="props.total"
                :per-page="serverParams.perPage"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>

    <b-modal
      id="generic-link-modal"
      v-model="genericLinkModal"
      title="Lien générique"
      hide-footer
    >
      <p class="my-2">
        Lien de remplissage et/ou signature générique du document
        <strong>« {{ genericLinkModel.title }} »</strong>
      </p>
      <b-input-group>
        <b-form-input
          ref="genericLink"
          :value="`${appDomain}/fill/${
            genericLinkModel.hash
          }?generic=1&noSign=${Number(genericLinkNoSignature)}`"
          disabled
        />
        <b-input-group-append>
          <b-button
            variant="primary"
            title="Copier le lien"
            @click="copyGenericLinkToClipboard()"
          >
            <i class="ri-file-copy-line ri-lg" />
          </b-button>
        </b-input-group-append>
      </b-input-group>
      <b-form-checkbox v-model="genericLinkNoSignature" class="my-2">
        Remplissage du document sans signature
        <i
          v-b-popover.hover.bottomright="
            'En cochant cette case, les données saisies par le signataire seront conservées dans votre annuaire VitalSign mais aucune signature électronique ne lui sera demandée.'
          "
          class="ri-question-line ri-lg align-middle"
        />
      </b-form-checkbox>
    </b-modal>
  </div>
</template>

<script>
import axios from "@axios";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import CardFile from "@/components/CardFile";
import TagFileType from "@/components/TagFileType";

export default {
  components: {
    CardFile,
    TagFileType,
  },
  data() {
    return {
      searchInput: "",
      appDomain: process.env.VUE_APP_DOMAIN,
      columns: [
        {
          label: "Nom",
          field: "title",
          sortable: true,
        },
        {
          label: "Type",
          field: "type",
          sortable: true,
          width: "25px",
        },
        {
          label: "Création",
          field: "createdAt",
          sortable: true,
          width: "150px",
        },
        {
          label: "Dernière mise à jour",
          field: "updatedAt",
          sortable: true,
          width: "150px",
        },
        { label: "", field: "action", width: "10px" },
      ],
      rows: [],
      recents: [],
      totalRecords: 0,
      isTableLoading: true,
      serverParams: {
        columnFilters: {},
        sort: {
          field: "createdAt",
          type: "desc",
        },
        page: 1,
        perPage: 10,
      },
      genericLinkModal: false,
      genericLinkNoSignature: false,
      genericLinkModel: {
        title: "",
        hash: "",
      },
      hideRecentModels: false,
    };
  },
  watch: {
    searchInput(val, _) {
      if (val === "") this.hideRecentModels = false;
      this.onColumnFilter({
        columnFilters: { title: val },
      });
    },
  },
  mounted() {
    this.fetchRecentModels();
  },
  methods: {
    openGenericLinkModal(model) {
      this.genericLinkModel = model;
      this.genericLinkModal = true;
    },
    copyGenericLinkToClipboard() {
      const text = this.$refs.genericLink.value;
      navigator.clipboard.writeText(text).then(
        () => {
          this.genericLinkModal = false;
        },
        (err) => {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },
    deleteModel(model) {
      this.$swal
        .fire({
          title: "Souhaitez-vous supprimer ce modèle ?",
          text: "Ce modèle sera supprimé définitivement.",
          icon: "warning",
          iconColor: "#F14A3F",
          confirmButtonText: "Supprimer",
          showCancelButton: true,
          buttonsStyling: false,
          width: "512px",
          customClass: {
            confirmButton: "btn btn-danger mr-50 my-1",
            cancelButton: "btn btn-outline-primary my-1",
          },
        })
        .then(({ isConfirmed }) => {
          if (isConfirmed) {
            axios
              .delete(`/document-templates/${model.id}`, {
                config: {
                  showToast: true,
                  requestToast: {
                    title: "Suppression en cours",
                  },
                  responseToast: {
                    title: "Suppression effectuée avec succès",
                  },
                },
              })
              .then((res) => {
                this.fetchItemsFromApi();
                this.fetchRecentModels();
              })
              .catch((err) => {
                console.error(err);
              });
          }
        });
    },

    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps };
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.fetchItemsFromApi();
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.onPageChange({ currentPage: 1 });
    },
    onSortChange([params]) {
      this.updateParams({
        sort: {
          field: params.field,
          type: params.type,
        },
      });
      this.onPageChange({ currentPage: 1 });
    },
    onColumnFilter(params) {
      this.updateParams(params);
      this.onPageChange({ currentPage: 1 });
    },
    /**
     * Fetch models from API and display them in the table
     */
    fetchItemsFromApi() {
      this.isTableLoading = true;

      const tableSearch = {};
      console.log(this.serverParams.columnFilters);
      Object.keys(this.serverParams.columnFilters).forEach((e) => {
        if (!this.serverParams.columnFilters[e]) return;
        tableSearch[`${e}_contains`] = this.serverParams.columnFilters[e];
      });

      const getApiParams = {
        ...tableSearch,
        // title: "Copie de Contract title",
        // title: { $regex: 'sans', $options: 'i' },
        limit: this.serverParams.perPage,
        page: this.serverParams.page,
        sortBy: `${this.serverParams.sort.field}:${this.serverParams.sort.type}`,
      };

      if (getApiParams.hasOwnProperty("title_contains")) {
        this.hideRecentModels = true;
      }

      axios
        .get("/document-templates", { params: getApiParams })
        .then((res) => {
          this.rows = res.data.results;
          this.totalRecords = res.data.totalResults;
          this.isTableLoading = false;
        })
        .catch((err) => {
          if (err.response.status === 401) this.sessionExpired();
          this.isTableLoading = false;
        });
    },
    /**
     * Fetch recent models from API
     */
    fetchRecentModels() {
      axios
        .get("/document-templates", {
          params: { limit: 4, page: 1, sortBy: "createdAt:desc" },
        })
        .then((res) => {
          this.recents = res.data.results;
        })
        .catch((err) => {
          if (err.response.status === 401) this.sessionExpired();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
h3 {
  font-weight: 400;
}

.dropdown .btn {
  padding: 0;
}

.input-group-append .btn {
  padding: 0.375rem 0.75rem;
}

input.search-input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: searchfield-cancel-button;
}

[dir] .input-group-text {
  border-right: none;
}

[dir] .form-control {
  border-left: none !important;
  padding-left: 0;
}
</style>
