<template>
  <div
    class="d-flex align-items-center"
    :class="tagObject"
  >
    <i
      class="ri-lg mr-50"
      :class="tagIcon"
    />
    <span class="text-bold">{{ getLabel }}</span>
  </div>
</template>

<script>
export default {
  props: {
    //type de template: 'toFill' or 'filled'
    type: {
      required: true,
      type: String,
    },
  },
  computed: {
    tagObject() {
      return {
        toFill: this.type === 'TO_FILL',
        filled: this.type === 'FILLED',
      };
    },
    tagIcon() {
      return {
        "ri-file-edit-fill": this.type === 'TO_FILL',
        "ri-file-list-2-fill": this.type === 'FILLED',
      };
    },
    getLabel() {
      return this.type === 'TO_FILL' ? 'À remplir' : 'Rempli';
    },
  },
};
</script>

<style lang="scss" scoped>
div {
    padding: 4px 8px;
    border-radius: $border-radius;
    font-family: $font-family-sans-serif;
    font-size: $font-size-sm;
    letter-spacing: -0.02rem;
}

.toFill {
  background-color: $purple-200;
  color: $purple;
}

.filled {
  background-color: $blue-200;
  color: $vitalsign-blue;
}

.text-bold {
    font-weight: 600;
}
</style>