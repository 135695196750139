<template>
  <div
    class="left card-border"
    :class="{
      toFill: type === 'TO_FILL',
      filled: type === 'FILLED',
      new: type === 'new',
    }"
  >
    <div class="card-top">
      <img
        v-if="type === 'TO_FILL'"
        src="../assets/images/icons/icon-model-to-fill.svg"
        class="icon-position"
      />
      <img
        v-else-if="type === 'FILLED'"
        src="../assets/images/icons/icon-model-filled.svg"
        class="icon-position"
      />
      <img
        v-else-if="type === 'new' && template === 'auth-certificate'"
        src="../assets/images/icons/icon-model-auth-certificate.svg"
        class="icon-position"
      />
      <img
        v-else-if="type === 'new' && template === 'collectif-auth-certificate'"
        src="../assets/images/icons/collectif-auth-certificate-icon.svg"
        class="icon-position"
      />
      <img
        v-else
        src="../assets/images/icons/icon-model-new.svg"
        class="icon-position"
      />
    </div>

    <div class="card-bottom p-1">
      <p class="m-0 text-black-500 file-title">
        <strong>
          {{ title }}
        </strong>
      </p>
      <p v-if="createdAt" class="m-0 text-black-400">
        Créé le {{ dateToStr(createdAt) }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardFile",
  props: {
    title: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    template: {
      type: String,
      required: false,
    },
    createdAt: {
      type: String,
      required: false,
      default: "",
    },
    updatedAt: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>

<style lang="scss">
.card-border {
  border: 1px solid $gray-400;
  border-radius: 8px 8px 8px 8px;
  transition: all 0.2s ease;
}

.card-border.filled:hover {
  border: 1px solid #2444ec;
}
.card-border.toFill:hover {
  border: 1px solid #7c24ec;
}
.card-border.new:hover {
  border: 1px solid #24b0ec;
}

.card-top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 136px;
  background: #f3f5f6;
  border-radius: 8px 8px 0px 0px;
  overflow: hidden;
}

.card-bottom {
  background: #fcfcfc;
  border-radius: 0px 0px 8px 8px;
}

.icon-position {
  margin: 0 auto;
  margin-top: 30px;
  width: 120px;
  height: auto;
}

.file-title {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
</style>